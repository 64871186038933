<template>
  <v-navigation-drawer v-model="drawer" permanent :mini-variant.sync="mini">
    <v-row
      no-gutters
      align="center"
      :style="!mini ? 'padding: 20px 36px' : 'padding: 20px 0px'"
    >
      <img
        src="@/assets/img/PTransBlackLogo.png"
        v-if="!mini"
        alt="logo"
        width="141px"
      />
      <v-row no-gutters :justify="mini ? 'center' : 'end'">
        <img
          src="@/assets/img/iconsSvg/menuIcon.svg"
          alt="menu icon"
          style="cursor: pointer"
          :style="mini ? 'transform:rotate(180deg)' : ''"
          @click="mini = !mini"
        />
      </v-row>
    </v-row>
    <p
      style="
        color: #4b5262;
        font-family: 'MacPaw Fixel';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 28px;
        margin-left: 36px;
      "
      v-if="!mini"
    >
      Основне
    </p>
    <v-list :style="mini ? '' : 'padding: 0px 16px'">
      <v-list-group
        style="padding: 0px"
        :class="
          activeItem == 'mainComponent' ||
          activeItem == 'aboutUsComponent' ||
          activeItem == 'contactComponent' ||
          activeItem == 'reviewsComponent' ||
          activeItem == 'faqComponent'
            ? 'menuActiveItem'
            : ''
        "
        @click="navigateTo('mainComponent')"
        v-model="activeListMain"
        v-if="user_role == 'admin'"
      >
        <template v-slot:activator>
          <v-list-item-title>
            <v-row no-gutters align="center">
              <div
                class="mainIcon"
                :style="mini ? 'margin-right: 0px;' : 'margin-right: 8px;'"
              />
              <span
                v-if="!mini"
                class="menuItemTitle"
                :class="
                  activeItem == 'mainComponent' ||
                  activeItem == 'aboutUsComponent' ||
                  activeItem == 'contactComponent' ||
                  activeItem == 'reviewsComponent' ||
                  activeItem == 'faqComponent'
                    ? 'menuActiveItem'
                    : ''
                "
                >Головна</span
              >
            </v-row>
          </v-list-item-title>
        </template>
        <div
          :style="
            activeListMain
              ? 'background-color: #f5f5f5; border-radius: 10px;'
              : ''
          "
        >
          <v-list-item link @click="navigateTo('aboutUsComponent')">
            <v-list-item-title class="menuItemSubtitle"
              >Про нас</v-list-item-title
            >
          </v-list-item>
          <v-list-item link @click="navigateTo('contactComponent')">
            <v-list-item-title class="menuItemSubtitle"
              >Контакти</v-list-item-title
            >
          </v-list-item>
          <v-list-item link @click="navigateTo('reviewsComponent')">
            <v-list-item-title class="menuItemSubtitle"
              >Відгуки</v-list-item-title
            >
          </v-list-item>
          <!-- <v-list-item link @click="navigateTo('our_plus')">
          <v-list-item-title class="menuItemSubtitle"
            >Наші переваги</v-list-item-title
          >
        </v-list-item> -->
          <v-list-item link @click="navigateTo('faqComponent')">
            <v-list-item-title class="menuItemSubtitle"
              >Поширені запитання</v-list-item-title
            >
          </v-list-item>
        </div>
      </v-list-group>
      <v-list-item
        v-else
        @click="navigateTo('mainComponent')"
        :class="activeItem == 'mainComponent' ? 'menuActiveItem' : ''"
      >
        <v-list-item-title>
          <v-row no-gutters align="center">
            <div
              class="mainIcon"
              :style="mini ? 'margin-right: 0px;' : 'margin-right: 8px;'"
            />
            <span
              v-if="!mini"
              class="menuItemTitle"
              :class="activeItem == 'mainComponent' ? 'menuActiveItem' : ''"
              >Головна</span
            >
          </v-row></v-list-item-title
        >
      </v-list-item>
      <v-list-item
        @click="navigateTo('reportsComponent')"
        :class="activeItem == 'reportsComponent' ? 'menuActiveItem' : ''"
      >
        <v-list-item-title>
          <v-row no-gutters align="center">
            <div
              class="reportIcon"
              :style="mini ? 'margin-right: 0px;' : 'margin-right: 8px;'"
            />
            <span
              v-if="!mini"
              class="menuItemTitle"
              :class="activeItem == 'reportsComponent' ? 'menuActiveItem' : ''"
              >Звітність</span
            >
          </v-row></v-list-item-title
        >
      </v-list-item>
      <v-list-group
        v-if="user_role == 'admin'"
        style="padding: 0px"
        :class="
          activeItem == 'routesComponent' ||
          activeItem == 'pricesComponent' ||
          activeItem == 'citiesComponent' ||
          activeItem == 'stationsComponent'
            ? 'menuActiveItem'
            : ''
        "
        @click="navigateTo('routesComponent')"
        v-model="activeListRoute"
      >
        <template v-slot:activator>
          <v-list-item-title>
            <v-row no-gutters align="center">
              <div
                class="routeIcon"
                :style="mini ? 'margin-right: 0px;' : 'margin-right: 8px;'"
              />
              <span
                v-if="!mini"
                class="menuItemTitle"
                :class="
                  activeItem == 'routesComponent' ||
                  activeItem == 'pricesComponent' ||
                  activeItem == 'citiesComponent' ||
                  activeItem == 'stationsComponent'
                    ? 'menuActiveItem'
                    : ''
                "
                >Маршрути</span
              >
            </v-row>
          </v-list-item-title>
        </template>
        <div
          :style="
            activeListRoute
              ? 'background-color: #f5f5f5; border-radius: 10px;'
              : ''
          "
        >
          <v-list-item link @click="navigateTo('pricesComponent')">
            <v-list-item-title class="menuItemSubtitle"
              >Таблиця цін</v-list-item-title
            >
          </v-list-item>
          <v-list-item link @click="navigateTo('citiesComponent')">
            <v-list-item-title class="menuItemSubtitle"
              >Міста</v-list-item-title
            >
          </v-list-item>
          <v-list-item link @click="navigateTo('stationsComponent')">
            <v-list-item-title class="menuItemSubtitle"
              >Зупинки</v-list-item-title
            >
          </v-list-item>
        </div>
      </v-list-group>
      <v-list-item
      v-else
        @click="navigateTo('routesComponent')"
        :class="activeItem == 'routesComponent' ? 'menuActiveItem' : ''"
      >
        <v-list-item-title>
          <v-row no-gutters align="center">
            <div
              class="routeIcon"
              :style="mini ? 'margin-right: 0px;' : 'margin-right: 8px;'"
            />
            <span
              v-if="!mini"
              class="menuItemTitle"
              :class="activeItem == 'routesComponent' ? 'menuActiveItem' : ''"
              >Маршрути</span
            >
          </v-row></v-list-item-title
        >
      </v-list-item>
      <v-list-group
        style="padding: 0px"
        v-model="activeListTrip"
        :class="
          activeItem == 'tripsComponent' || activeItem == 'orderHistory'
            ? 'menuActiveItem'
            : ''
        "
        @click="navigateTo('tripsComponent')"
      >
        <template v-slot:activator>
          <v-list-item-title>
            <v-row no-gutters align="center">
              <div
                class="ticketIcon"
                :style="mini ? 'margin-right: 0px;' : 'margin-right: 8px;'"
              />
              <span
                v-if="!mini"
                class="menuItemTitle"
                :class="
                  activeItem == 'tripsComponent' || activeItem == 'orderHistory'
                    ? 'menuActiveItem'
                    : ''
                "
                >Рейси</span
              >
            </v-row>
          </v-list-item-title>
        </template>
        <div
          :style="
            activeListTrip
              ? 'background-color: #f5f5f5; border-radius: 10px;'
              : ''
          "
        >
          <v-list-item link @click="navigateTo('orderHistory')">
            <v-list-item-title class="menuItemSubtitle"
              >Історія продаж</v-list-item-title
            >
          </v-list-item>
        </div>
      </v-list-group>
      <v-list-item
        @click="navigateTo('usersComponent')"
        :class="activeItem == 'usersComponent' ? 'menuActiveItem' : ''"
      >
        <v-list-item-title>
          <v-row no-gutters align="center">
            <div
              class="usersIcon"
              :style="mini ? 'margin-right: 0px;' : 'margin-right: 8px;'"
            />
            <span
              v-if="!mini"
              class="menuItemTitle"
              :class="activeItem == 'usersComponent' ? 'menuActiveItem' : ''"
              >База клієнтів</span
            >
          </v-row></v-list-item-title
        >
      </v-list-item>
      <v-list-item
        v-if="user_role == 'admin'"
        @click="navigateTo('autoparkComponent')"
        :class="activeItem == 'autoparkComponent' ? 'menuActiveItem' : ''"
      >
        <v-list-item-title>
          <v-row no-gutters align="center">
            <div
              class="busIcon"
              :style="mini ? 'margin-right: 0px;' : 'margin-right: 8px;'"
            />
            <span
              v-if="!mini"
              class="menuItemTitle"
              :class="activeItem == 'autoparkComponent' ? 'menuActiveItem' : ''"
              >Автопарк</span
            >
          </v-row></v-list-item-title
        >
      </v-list-item>
      <v-list-item
        v-if="user_role == 'admin'"
        @click="navigateTo('blogComponent')"
        :class="activeItem == 'blogComponent' ? 'menuActiveItem' : ''"
      >
        <v-list-item-title>
          <v-row no-gutters align="center">
            <div
              class="blogIcon"
              :style="mini ? 'margin-right: 0px;' : 'margin-right: 8px;'"
            />
            <span
              v-if="!mini"
              class="menuItemTitle"
              :class="activeItem == 'blogComponent' ? 'menuActiveItem' : ''"
              >Блог</span
            >
          </v-row></v-list-item-title
        >
      </v-list-item>
      <v-list-item
        link
        @click="navigateTo('messageComponent')"
        :class="activeItem == 'messageComponent' ? 'menuActiveItem' : ''"
      >
        <v-list-item-title>
          <v-row no-gutters align="center">
            <div
              class="messageIcon"
              :style="mini ? 'margin-right: 0px;' : 'margin-right: 8px;'"
            />
            <span
              v-if="!mini"
              class="menuItemTitle"
              :class="activeItem == 'messageComponent' ? 'menuActiveItem' : ''"
              >Повідомлення</span
            >
          </v-row></v-list-item-title
        >
      </v-list-item>
      <v-list-group
        v-if="user_role == 'admin'"
        style="padding: 0px"
        :class="
          activeItem == 'promocodeComponent' ||
          activeItem == 'promotionComponent' ||
          activeItem == 'socialComponent'
            ? 'menuActiveItem'
            : ''
        "
        @click="navigateTo('promotionComponent')"
        v-model="activeListDiscount"
      >
        <template v-slot:activator>
          <v-list-item-title>
            <v-row no-gutters align="center">
              <div
                class="currencyIcon"
                :style="
                  mini ? 'margin-right: 0px;' : 'margin-right: 8px!important;'
                "
              />
              <v-col cols="9">
                <span
                  v-if="!mini"
                  class="menuItemTitle"
                  :class="
                    activeItem == 'promocodeComponent' ||
                    activeItem == 'promotionComponent' ||
                    activeItem == 'socialComponent'
                      ? 'menuActiveItem'
                      : ''
                  "
                  >Знижки</span
                >
              </v-col>
            </v-row>
          </v-list-item-title>
        </template>
        <div
          :style="
            activeListDiscount
              ? 'background-color: #f5f5f5; border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;'
              : ''
          "
        >
          <v-list-item link @click="navigateTo('promotionComponent')">
            <v-list-item-title class="menuItemSubtitle"
              >Знижки</v-list-item-title
            >
          </v-list-item>
          <v-list-item link @click="navigateTo('promocodeComponent')">
            <v-list-item-title class="menuItemSubtitle"
              >Промокоди</v-list-item-title
            >
          </v-list-item>
          <v-list-item link @click="navigateTo('socialComponent')">
            <v-list-item-title class="menuItemSubtitle"
              >Пільги</v-list-item-title
            >
          </v-list-item>
        </div>
      </v-list-group>
    </v-list>
    <p
      style="
        color: #4b5262;
        font-family: 'MacPaw Fixel';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 28px;
        margin-left: 36px;
      "
      v-if="!mini"
    >
      Посилання
    </p>
    <v-list :style="mini ? '' : 'padding: 0px 16px'">
      <v-list-item
        :class="activeItem == '' ? 'menuActiveItem' : ''"
        link
        @click="$router.push('/')"
      >
        <v-list-item-title>
          <v-row no-gutters align="center">
            <div
              class="pushToSiteIcon"
              :style="
                mini ? 'margin-right: 0px;' : 'margin-right: 8px!important;'
              "
            />
            <span v-if="!mini" class="menuItemTitle">Перейти на сайт</span>
          </v-row></v-list-item-title
        >
      </v-list-item>
    </v-list>
    <p
      style="
        color: #4b5262;
        font-family: 'MacPaw Fixel';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 28px;
        margin-left: 36px;
      "
      v-if="!mini"
    >
      Додатково
    </p>
    <v-list :style="mini ? '' : 'padding: 0px 16px'">
      <v-list-group
        v-if="user_role == 'admin'"
        style="padding: 0px"
        :class="
          activeItem == 'liqpayComponent' || activeItem == 'blinkComponent'
            ? 'menuActiveItem'
            : ''
        "
        @click="navigateTo('liqpayComponent')"
        v-model="activeListPayment"
      >
        <template v-slot:activator>
          <v-list-item-title>
            <v-row no-gutters align="center">
              <div
                class="currencyIcon"
                :style="
                  mini ? 'margin-right: 0px;' : 'margin-right: 8px!important;'
                "
              />
              <v-col cols="9">
                <span
                  v-if="!mini"
                  class="menuItemTitle"
                  :class="
                    activeItem == 'liqpayComponent' ||
                    activeItem == 'blinkComponent'
                      ? 'menuActiveItem'
                      : ''
                  "
                  >Платіжна <br />система</span
                >
              </v-col>
            </v-row>
          </v-list-item-title>
        </template>
        <div
          :style="
            activeListPayment
              ? 'background-color: #f5f5f5; border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;'
              : ''
          "
        >
          <v-list-item link @click="navigateTo('liqpayComponent')">
            <v-list-item-title class="menuItemSubtitle"
              >Liqpay</v-list-item-title
            >
          </v-list-item>
          <v-list-item link @click="navigateTo('blinkComponent')">
            <v-list-item-title class="menuItemSubtitle"
              >BlinkPay</v-list-item-title
            >
          </v-list-item>
        </div>
      </v-list-group>
      <v-list-item
        v-if="user_role == 'admin'"
        :class="activeItem == 'workerComponent' ? 'menuActiveItem' : ''"
        link
        @click="navigateTo('workerComponent')"
      >
        <v-list-item-title>
          <v-row no-gutters align="center">
            <div
              class="workerIcon"
              :style="
                mini ? 'margin-right: 0px;' : 'margin-right: 8px!important;'
              "
            />
            <span
              v-if="!mini"
              class="menuItemTitle"
              :class="activeItem == 'workerComponent' ? 'menuActiveItem' : ''"
              >Працівники</span
            >
          </v-row></v-list-item-title
        >
      </v-list-item>
      <v-list-item
        :class="activeItem == 'settingComponent' ? 'menuActiveItem' : ''"
        link
        @click="navigateTo('settingComponent')"
        style="margin-bottom: 50px"
      >
        <v-list-item-title>
          <v-row no-gutters align="center">
            <div
              class="settingIcon"
              :style="
                mini ? 'margin-right: 0px;' : 'margin-right: 8px!important;'
              "
            />
            <span
              v-if="!mini"
              class="menuItemTitle"
              :class="activeItem == 'settingComponent' ? 'menuActiveItem' : ''"
              >Налаштування</span
            >
          </v-row></v-list-item-title
        >
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>
  
  <script>
import { mapGetters } from "vuex";
export default {
  data: () => ({
    drawer: true,
    mini: false,
    activeListMain: false,
    activeListRoute: false,
    activeListTrip: false,
    activeListDiscount: false,
    activeListPayment: false,
    activeItem: "main",
  }),
  mounted() {
    this.checkActiveTab();
  },
  methods: {
    navigateTo(route) {
      this.activeItem = route;
      this.$router.push({ name: route });
    },
    checkActiveTab() {
      this.activeItem = this.$route.name;
      if (this.activeItem == "mainComponent" && !this.activeListMain) {
        this.activeListMain = true;
      }
      if (this.activeItem == "routesComponent" && !this.activeListRoute) {
        this.activeListRoute = true;
      }
      if (this.activeItem == "tripsComponent" && !this.activeListTrip) {
        this.activeListTrip = true;
      }
      if (this.activeItem == "promotionComponent" && !this.activeListDiscount) {
        this.activeListDiscount = true;
      }
      if (this.activeItem == "liqpayComponent" && !this.activeListPayment) {
        this.activeListPayment = true;
      }
    },
  },
  computed: {
    ...mapGetters(["loggedUserPTrans"]),
    user_role() {
      return this.loggedUserPTrans?.role;
    },
  },
  watch: {
    activeItem: {
      handler() {
        if (this.activeItem == "our_fleet" || this.activeItem == "message") {
          this.activeListMain = false;
          this.activeListRoute = false;
          this.activeListTrip = false;
        }
        if (
          this.activeItem !== "mainComponent" &&
          this.activeItem !== "aboutUsComponent" &&
          this.activeItem !== "contactComponent" &&
          this.activeItem !== "reviewsComponent" &&
          this.activeItem !== "faqComponent"
        ) {
          this.activeListMain = false;
        }
        if (
          this.activeItem !== "routesComponent" &&
          this.activeItem !== "pricesComponent" &&
          this.activeItem !== "citiesComponent" &&
          this.activeItem !== "stationsComponent"
        ) {
          this.activeListRoute = false;
        }
        if (
          this.activeItem !== "tripsComponent" &&
          this.activeItem !== "orderHistory"
        ) {
          this.activeListTrip = false;
        }
        if (
          this.activeItem !== "promotionComponent" &&
          this.activeItem !== "promocodeComponent" &&
          this.activeItem !== "socialComponent"
        ) {
          this.activeListDiscount = false;
        }
        if (
          this.activeItem !== "liqpayComponent" &&
          this.activeItem !== "blinkComponent"
        ) {
          this.activeListPayment = false;
        }
        this.$emit("changeActiveItem", this.activeItem);
      },
    },
    activeListMain: {
      handler() {
        if (this.activeListMain) {
          this.activeItem = "mainComponent";
        }
      },
    },
    activeListRoute: {
      handler() {
        if (this.activeListRoute) {
          this.activeItem = "routesComponent";
        }
      },
    },
    activeListTrip: {
      handler() {
        if (this.activeListTrip) {
          this.activeItem = "tripsComponent";
        }
      },
    },
    activeListDiscount: {
      handler() {
        if (this.activeListDiscount) {
          this.activeItem = "promotionComponent";
        }
      },
    },
    activeListPayment: {
      handler() {
        if (this.activeListPayment) {
          this.activeItem = "liqpayComponent";
        }
      },
    },
    mini: {
      handler() {
        if (this.mini) {
          this.activeListDiscount = false;
          this.activeListMain = false;
          this.activeListTrip = false;
          this.activeListRoute = false;
          this.activeListPayment = false;
        }
      },
    },
    "$route.path": {
      handler() {
        this.checkActiveTab();
      },
    },
  },
};
</script>
  
  <style scoped>
.menuItemTitle {
  color: #4b5262 !important;
  font-family: "MacPaw Fixel";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}
.menuItemSubtitle {
  color: #4b5262 !important;
  font-family: "MacPaw Fixel";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}
.menuActiveItem {
  color: #144fa9 !important;
  border-radius: 10px;
  background: #e7edf6;
}
</style>
<style lang="scss">
.menuActiveItem .currencyIcon {
  background-color: #144fa9 !important;
}
.menuActiveItem .messageIcon {
  background-color: #144fa9 !important;
}
.menuActiveItem .blogIcon {
  background-color: #144fa9 !important;
}
.menuActiveItem .busIcon {
  background-color: #144fa9 !important;
}
.menuActiveItem .usersIcon {
  background-color: #144fa9 !important;
}
.menuActiveItem .ticketIcon {
  background-color: #144fa9 !important;
}
.menuActiveItem .routeIcon {
  background-color: #144fa9 !important;
}
.menuActiveItem .mainIcon {
  background-color: #144fa9 !important;
}
.menuActiveItem .settingIcon {
  background-color: #144fa9 !important;
}
.menuActiveItem .workerIcon {
  background-color: #144fa9 !important;
}
.menuActiveItem .reportIcon {
  background-color: #144fa9 !important;
}
</style>
  